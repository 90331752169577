"use client";

import * as React from "react";

import { supabaseBrowserClient } from "~/lib/supabaseBrowserClient";
import { useRouter } from "next/router";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { cn } from "~/lib/utils";
import { api } from "~/utils/api";
import { castError } from "~/utils/errors";
import Link from "next/link";
import { Loader } from "lucide-react";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onSendLink: (email: string) => void;
}

export function UserAuthForm({
  className,
  onSendLink,
  ...props
}: UserAuthFormProps) {
  const router = useRouter();
  const supabase = supabaseBrowserClient;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [error, setError] = React.useState<string | null>(null);

  const upsertInvitation = api.auth.upsertInvitation.useMutation();

  async function onSubmit(event: React.SyntheticEvent) {
    console.log(
      `muly:user-auth-form:onSubmit ${JSON.stringify(router.query, null, 2)}`,
      {
        event,
        email,
        query: router.query,
      },
    );

    const { redirectedFrom, hotelId, invitationKey } = router.query;
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!email) {
        setError("Email is required");
        return;
      }

      setError(null);

      let canLogin = false;
      if (invitationKey) {
        const answer = await upsertInvitation.mutateAsync({
          email,
          hotelId: hotelId as string,
          invitationKey: invitationKey as string,
        });

        canLogin = answer.success;
      } else {
        canLogin = true;
      }

      if (canLogin) {
        const emailRedirectTo = `${window.location.origin}/login${
          redirectedFrom ? `?redirectedFrom=${redirectedFrom}` : ""
        }`;

        const { data, error } = await supabase.auth.signInWithOtp({
          email,
          options: {
            emailRedirectTo,
            shouldCreateUser: true,
          },
        });

        console.log(`muly:user-auth-form:onSubmit`, {
          emailRedirectTo,
          data,
          error,
          email,
          hotelId,
          invitationKey,
        });

        if (error) {
          setError(error.message);
        } else {
          console.log(`muly:UserAuthForm`, { data, emailRedirectTo });
          onSendLink(email);
        }
      }
    } catch (e) {
      const error = castError(e);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-8">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
            />
            {!!error && <label className="text-critical">{error}</label>}
          </div>
          <p className="text-left text-sm text-blueblack2">
            By creating an account, you agree to our{" "}
            <Link
              target="_blank"
              href="/privacy"
              className="hover:text-blueblack/90 text-blueblack"
            >
              Privacy Policy
            </Link>
            and
            <Link
              target="_blank"
              href="/terms"
              className="hover:text-blueblack/90 text-blueblack"
            >
              Terms of Use
            </Link>
            .
          </p>
          <Button
            disabled={isLoading}
            className="!bg-blueblack hover:!bg-blueblack"
          >
            {isLoading && <Loader className="mr-2 size-4 animate-spin" />}
            Send Magic Link
          </Button>
          <Link
            href={`/login-with-password${email ? `?email=${encodeURIComponent(email)}` : ""}`}
            className="text-center text-sm text-grey1 hover:underline"
          >
            Login with password
          </Link>
        </div>
      </form>
    </div>
  );
}
