import { useQueryState } from "next-usequerystate";
import Link from "next/link";

import type { Session } from "@supabase/supabase-js";
import { supabaseBrowserClient } from "~/lib/supabaseBrowserClient";
import { Square } from "lucide-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { UserAuthForm } from "~/components/authentication/user-auth-form";
import { Loading } from "~/components/common/Loading";
import { useConfigContext } from "~/components/common/config/config-context";
import { LogoIcon } from "~/components/landing-page/parts/Icons";
import { api } from "~/utils/api";

export default function AuthenticationPage() {
  const router = useRouter();
  const [email, setEmail] = useQueryState("email");
  const supabase = supabaseBrowserClient;
  const upsertUserProfile = api.auth.upsertUserProfile.useMutation();
  const allowHotelForUser = api.auth.allowHotelForUser.useMutation();
  const { userProfile } = useConfigContext();
  const [isReady, setIsReady] = useState(false);

  console.log(`muly:AuthenticationPage`, {
    q: router.query,
  });

  const onAuth = // useCallback(
    async (sessionClient: Session | null) => {
      const { redirectedFrom, hotelId, invitationKey } = router.query;

      if (!userProfile) {
        if (!upsertUserProfile.isPending) {
          await upsertUserProfile.mutateAsync({
            hotelId: hotelId as string,
            invitationKey: invitationKey as string,
          });
          await router.replace("/");
          console.log(`muly:onAuth after upsertUserProfile`, {
            q: router.query,
          });
        } else {
          console.log(`muly:onAuth upsertUserProfile.isLoading skip`, {
            q: router.query,
          });
        }
      } else {
        if (hotelId && invitationKey) {
          // add user to invited hotel
          await allowHotelForUser.mutateAsync({
            hotelId: hotelId as string,
            invitationKey: invitationKey as string,
          });
        }
        if (hotelId) {
          await router.replace(`/${hotelId as string}`);
        } else {
          await router.replace("/");
        }
        // console.log(`muly:onAuth userProfile already exists`, {
        //   hotelId,
        //   userProfile,
        //   q: router.query,
        // });
      }
    };

  useEffect(
    () => {
      const authListener = supabase.auth.onAuthStateChange(
        (event, sessionClient) => {
          if (sessionClient?.user?.id) {
            onAuth(sessionClient).catch((err) => {
              console.error(`muly:onAuthStateChange:onAuth:error`, err);
            });
          } else {
            setIsReady(true);
          }

          if (event === "SIGNED_IN") {
            console.log("User signed in");
            void router.replace("/");
          } else if (event === "SIGNED_OUT") {
            console.log("User signed out");
          }
        },
      );

      // Clean up the listener when the component unmounts
      return () => {
        authListener.data.subscription.unsubscribe();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!isReady) {
    return <Loading />;
  }

  return (
    <div className="container relative h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-blueblack p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0 bg-blueblack" />
        <div className="relative z-20 flex h-full flex-col justify-between">
          <div>
            <div className="flex items-center">
              <LogoIcon className="mr-2 size-8 text-grey1" opacity={true} />
              <div className="text-hlg text-white">Rate Quantum</div>
            </div>
            <div className="ml-10 mt-10">
              <div className="relative flex h-36 w-40">
                <Square
                  className="absolute right-0 top-0 h-24 w-28 text-grey4 opacity-10"
                  fill="white"
                />
                <Square
                  className="absolute bottom-0 left-0 h-24 w-28 stroke-white opacity-70"
                  strokeWidth={1}
                />
              </div>
            </div>
          </div>
          <blockquote className="mx-auto mb-20 space-y-10 text-center">
            <p className="text-sm">Relax. Change is coming.</p>
            <div className="mx-auto w-fit pr-2">
              <div className="relative flex h-2 w-24 items-center justify-center">
                <div className="absolute left-0 size-2 rounded-full bg-grey1 opacity-50" />
                <div className="h-2 w-11 rounded-full bg-white" />
                <div className="absolute right-0 size-2 rounded-full bg-grey1 opacity-50" />
              </div>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-96">
          {!email && (
            <>
              <div className="flex flex-col space-y-2 text-center">
                <h1 className="text-hlg text-blueblack">
                  Insert your email below
                </h1>
                <p className="text-hsm text-grey1">Rate Quantum Revenue AI</p>
                <div className="relative !mb-2 !mt-12">
                  <hr className="-mb-3 w-full" />
                  <p className="z-10 mx-auto w-fit bg-white px-5 text-sm text-blueblack2">
                    Your Email
                  </p>
                </div>
              </div>
              <UserAuthForm onSendLink={setEmail} />
            </>
          )}
          {!!email && (
            <div className="text-center">
              <p className="text-hsm">Check your email</p>
              <p className="text-sm text-blueblack2">
                We've sent a magic link to {email}. Click the link to continue.
              </p>
            </div>
          )}
        </div>
        <div className="absolute bottom-0 flex w-webkit-fill-available flex-wrap justify-between gap-4 pr-8">
          <Link
            href="/privacy"
            target="_blank"
            className="hover:text-grey1/90 text-hsm text-grey1"
          >
            Privacy Policy
          </Link>
          <p className="hover:text-grey1/90 text-hsm text-grey1">
            2024 © Rate Quantum
          </p>
        </div>
      </div>
    </div>
  );
}

AuthenticationPage.disableLayout = true;
AuthenticationPage.disableNeedUser = true;
AuthenticationPage.disableNeedHotelData = true;
